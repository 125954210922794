<template>
  <div class="iframe-container">
    <video ref="videoPlayer" class="video-js responsive-iframe"></video>
  </div>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      player: null,
    }
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      this.player.log('onPlayerReady', this);
    })
    this.player.on('ended', () => {
      console.log('Duration', this.player.duration())
      this.$store.dispatch('question/storeEndVideo', true)
      this.$store.dispatch('question/storeStartVideo', false)
      this.$store.dispatch('question/storeShowQuestionTab', false)
      this.$store.dispatch('question/storeVideoDuration', this.player.duration())
    })
    this.player.on('play', () => {
      this.$store.dispatch('question/storeStartVideo', true)
    })
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
}
</script>

<style scoped>
.video-js {
  width: 100%;
  height: 100%;
}

.iframe-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}
</style>