<template>
    <v-container>
        <Message />
        <v-expansion-panels popout focusable>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <b>{{ $t('generalSettings') }}</b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-form ref="formGeneral">
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('siteTitle')" v-model="title" :rules="[rules.required]"
                                    v-on:keyup="setTitle"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('welcomeMessage')" v-model="description"
                                    :rules="[rules.required]" v-on:keyup="setDescription"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('copyrightText')" v-model="footerText"
                                    :rules="[rules.required]" v-on:keyup="setFooterText"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-checkbox :label="$t('allowWelcomeEmail')" v-model="allowEmail"></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-file-input :label="$t('siteLogo')" accept="image/*" prepend-icon="mdi-camera"
                                    @change="onLogoSelected" show-size></v-file-input>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-img :src="previewLogo" max-height="100" max-width="150" contain></v-img>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('appVersion')" type="number" v-model="appVersion"
                                    :rules="numberRules"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-file-input :label="$t('siteFavicon')" accept="image/*" prepend-icon="mdi-camera"
                                    @change="onFaviconSelected" show-size></v-file-input>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-img :src="previewFavicon" max-height="100" max-width="150" contain></v-img>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4" md="4">
                                <v-btn color="primary" @click="saveGeneral">{{ $t('save') }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <b>{{ $t('examSettings') }}</b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-form ref="formExam">
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-checkbox :label="$t('browserrefreshendtheexam')" v-model="endExam"></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-checkbox :label="$t('tabChangeEndTheExam')" v-model="tabChangeEndExam"></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-checkbox :label="$t('sitelogoonexampage')" v-model="examPageLogo"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('audioVideoLength')" type="number" v-model="audioVideoLength"
                                    :rules="numberRules"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4" md="4">
                                <v-btn color="primary" @click="saveExam">{{ $t('save') }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <b>{{ $t('paymentSettings') }}</b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <h4>{{ $t('stripe') }}</h4>
                        </v-col>
                    </v-row>
                    <v-form ref="formPayment">
                        <v-row>
                            <v-col cols="12" sm="2">
                                <v-text-field :label="$t('currency')" v-model="currency" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="2">
                                <v-text-field :label="$t('currencySymbol')" v-model="currencySymbol"
                                    readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-text-field :label="$t('stripeSecretkey')" v-model="stripeSecretKey"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4" md="4">
                                <v-btn color="primary" @click="savePayment">{{ $t('save') }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <b>{{ $t('emailSettings') }}</b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-form ref="formEmail">
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('email')" v-model="email"
                                    :rules="[rules.emailRules]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('password')" v-model="emailPassword"
                                    :type="'password'"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="2">
                                <v-text-field :label="$t('port')" type="number" v-model="emailPort"
                                    :rules="numberRules"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="2">
                                <v-text-field :label="$t('hostSmtp')" v-model="emailHost"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4" md="4">
                                <v-btn color="primary" @click="saveEmail">{{ $t('save') }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <b>{{ $t('colorSettings') }}</b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-form ref="formColor">
                        <v-row>
                            <v-col cols="12" sm="3">
                                <v-color-picker v-model="appBarPicker" dot-size="25" mode="hexa"
                                    swatches-max-height="200" hide-mode-switch></v-color-picker>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-color-picker v-model="headerPicker" dot-size="25" mode="hexa"
                                    swatches-max-height="200" hide-mode-switch></v-color-picker>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-color-picker v-model="bodyPicker" dot-size="25" mode="hexa" swatches-max-height="200"
                                    hide-mode-switch></v-color-picker>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-color-picker v-model="footerPicker" dot-size="25" mode="hexa"
                                    swatches-max-height="200" hide-mode-switch></v-color-picker>
                            </v-col>
                        </v-row>
                        <v-row v-show="true">
                            <v-col cols="12" sm="3">
                                <span>{{ colorAppBar }}</span>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <span>{{ colorHeader }}</span>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <span>{{ colorBody }}</span>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <span>{{ colorFooter }}</span>
                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4" md="4">
                                <v-btn color="primary" @click="saveColor">{{ $t('save') }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <b>{{ $t('landingPage') }}</b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-form ref="formLanding">
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('homeHeaderTop')" v-model="homeHeader1"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('homeDetailTop')" v-model="homeDetail1"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-file-input :label="$t('image')" accept="image/*" prepend-icon="mdi-camera"
                                    @change="onHomePictureSelected" show-size></v-file-input>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-img :src="previewHomePicture" max-height="200" max-width="350" contain></v-img>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('homeHeaderBottom')" v-model="homeHeader2"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('homeDetailBottom')" v-model="homeDetail2"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('homeBox1Header')" v-model="homeBox1Header"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('homeBox1Detail')" v-model="homeBox1Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('homeBox2Header')" v-model="homeBox2Header"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('homeBox2Detail')" v-model="homeBox2Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('homeBox3Header')" v-model="homeBox3Header"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('homeBox3Detail')" v-model="homeBox3Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('homeBox4Header')" v-model="homeBox4Header"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('homeBox4Detail')" v-model="homeBox4Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea :label="$t('pricingBriefText')" rows="2" v-model="pricingBriefText"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea :label="$t('featureBriefText')" rows="2" v-model="featureBriefText"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('feature1Header')" v-model="feature1Header"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('feature1Detail')" v-model="feature1Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-file-input :label="$t('image')" accept="image/*" prepend-icon="mdi-camera"
                                    @change="onFeature1PictureSelected" show-size></v-file-input>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-img :src="previewFeature1Picture" max-height="200" max-width="350" contain></v-img>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('feature2Header')" v-model="feature2Header"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('feature2Detail')" v-model="feature2Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-file-input :label="$t('image')" accept="image/*" prepend-icon="mdi-camera"
                                    @change="onFeature2PictureSelected" show-size></v-file-input>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-img :src="previewFeature2Picture" max-height="200" max-width="350" contain></v-img>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('feature3Header')" v-model="feature3Header"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('feature3Detail')" v-model="feature3Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-file-input :label="$t('image')" accept="image/*" prepend-icon="mdi-camera"
                                    @change="onFeature3PictureSelected" show-size></v-file-input>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-img :src="previewFeature3Picture" max-height="200" max-width="350" contain></v-img>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('feature4Header')" v-model="feature4Header"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('feature4Detail')" v-model="feature4Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-file-input :label="$t('image')" accept="image/*" prepend-icon="mdi-camera"
                                    @change="onFeature4PictureSelected" show-size></v-file-input>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-img :src="previewFeature4Picture" max-height="200" max-width="350" contain></v-img>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea :label="$t('benefitsBriefText')" rows="2" v-model="benefitsBriefText"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('benefit1Title')" v-model="benefit1Title"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('benefit1Detail')" rows="2" v-model="benefit1Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('benefit2Title')" v-model="benefit2Title"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('benefit2Detail')" rows="2" v-model="benefit2Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('benefit3Title')" v-model="benefit3Title"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('benefit3Detail')" rows="2" v-model="benefit3Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('benefit4Title')" v-model="benefit4Title"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('benefit4Detail')" rows="2" v-model="benefit4Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('benefit5Title')" v-model="benefit5Title"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('benefit5Detail')" rows="2" v-model="benefit5Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('benefit6Title')" v-model="benefit6Title"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('benefit6Detail')" rows="2" v-model="benefit6Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('benefit7Title')" v-model="benefit7Title"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('benefit7Detail')" rows="2" v-model="benefit7Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('benefit8Title')" v-model="benefit8Title"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('benefit8Detail')" rows="2" v-model="benefit8Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('benefit9Title')" v-model="benefit9Title"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('benefit9Detail')" rows="2" v-model="benefit9Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('benefit10Title')" v-model="benefit10Title"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('benefit10Detail')" rows="2" v-model="benefit10Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('benefit11Title')" v-model="benefit11Title"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('benefit11Detail')" rows="2" v-model="benefit11Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('benefit12Title')" v-model="benefit12Title"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('benefit12Detail')" rows="2" v-model="benefit12Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('benefit13Title')" v-model="benefit13Title"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('benefit13Detail')" rows="2" v-model="benefit13Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('benefit14Title')" v-model="benefit14Title"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('benefit14Detail')" rows="2" v-model="benefit14Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('benefit15Title')" v-model="benefit15Title"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('benefit15Detail')" rows="2" v-model="benefit15Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('benefit16Title')" v-model="benefit16Title"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-textarea :label="$t('benefit16Detail')" rows="2" v-model="benefit16Detail"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea :label="$t('registrationText')" v-model="registrationText"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea :label="$t('contactUsBriefText')" rows="2" v-model="contactUsBriefText"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-textarea :label="$t('conactusText')" v-model="contactUsText"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('conactusTelephone')" v-model="contactUsTelephone"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('conactusEmail')" v-model="contactUsEmail"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea :label="$t('faqBriefText')" rows="2" v-model="faqBriefText"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea :label="$t('landingFooterText')" v-model="footerTextLanding"
                                    :rules="[rules.required]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3">
                                <v-text-field :label="$t('footerFacebook')" v-model="footerFacebook"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-text-field :label="$t('footerTwitter')" v-model="footerTwitter"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-text-field :label="$t('footerLinkedIn')" v-model="footerLinkedin"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-text-field :label="$t('footerInstagram')" v-model="footerInstagram"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4" md="4">
                                <v-btn color="primary" @click="saveLanding">{{ $t('save') }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <b>{{ $t('emailText') }}</b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-form ref="formEmailText">
                        <v-row>
                            <v-col cols="12" sm="4">
                                <h4>{{ $t('forgetPasswordEmail') }}</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('subject')" v-model="forgetPasswordEmailSubject"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('header')" v-model="forgetPasswordEmailHeader"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-textarea :label="$t('body')" v-model="forgetPasswordEmailBody"
                                    :rules="[rules.required]"
                                    hint="[siteTitle]=Name of this site, [password]=User Password"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <h4>{{ $t('welcomeEmail') }}</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('subject')" v-model="welcomeEmailSubject"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('header')" v-model="welcomeEmailHeader"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-textarea :label="$t('body')" v-model="welcomeEmailBody" :rules="[rules.required]"
                                    hint="[siteTitle]=Name of this site, [password]=User Password, [email]=User Email"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <h4>{{ $t('invitationEmail') }}</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('subject')" v-model="invitationEmailSubject"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('header')" v-model="invitationEmailHeader"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-textarea :label="$t('body')" v-model="invitationEmailBody" :rules="[rules.required]"
                                    hint="[siteTitle]=Name of this site"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <h4>{{ $t('reportEmail') }}</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('subject')" v-model="reportEmailSubject"
                                    :rules="[rules.required]" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field :label="$t('header')" v-model="reportEmailHeader"
                                    :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-textarea :label="$t('body')" v-model="reportEmailBody" :rules="[rules.required]"
                                    readonly></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4" md="4">
                                <v-btn color="primary" @click="saveEmailText">{{ $t('save') }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>

<script>
import Message from '../../components/common/Message'
import config from '../../../public/config'

export default {
    name: 'AppSettings',
    components: {
        Message
    },
    data() {
        return {
            rules: {
                required: value => !!value || this.$t('required'),
                emailRules: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('emailInvalid'),
            },
            numberRules: [
                v => String(v).length > 0 || this.$t('thisFieldMaynotbeEmpty'),
                v => Number.isInteger(Number(v)) || this.$t('theValueMustbeanIntegerNumber'),
                v => v > 0 || this.$t('theValueMustBeGreaterThanZero')
            ],
            colors: ['blue-grey darken-1', 'blue-grey darken-2', 'blue-grey darken-3', 'blue-grey darken-4', 'grey darken-3'],
            bodyColors: ['grey lighten-1', 'grey lighten-2', 'grey lighten-3', 'grey lighten-4', 'grey lighten-5'],
            appBarColorSelect: null,
            footerColorSelect: null,
            bodyColorSelect: null,
            title: '',
            description: '',
            footerText: '',
            appVersion: 1,
            endExam: false,
            tabChangeEndExam: false,
            examPageLogo: false,
            audioVideoLength: 300,
            paidRegistration: false,
            email: '',
            emailPassword: '',
            emailPort: '',
            emailHost: '',
            currency: '',
            currencySymbol: '',
            registrationPrice: 0,
            stripePubKey: '',
            stripeSecretKey: '',
            allowEmail: null,
            allowFaq: null,
            previewLogo: null,
            selectedLogoFile: null,
            logoPath: '',
            previewFavicon: null,
            selectedFaviconFile: null,
            faviconPath: '',
            previewHomePicture: null,
            selectedHomePictureFile: null,
            homePicturePath: '',
            homeHeader1: '',
            homeDetail1: '',
            homeHeader2: '',
            homeDetail2: '',
            homeBox1Header: '',
            homeBox1Detail: '',
            homeBox2Header: '',
            homeBox2Detail: '',
            homeBox3Header: '',
            homeBox3Detail: '',
            homeBox4Header: '',
            homeBox4Detail: '',
            pricingBriefText: '',
            featureBriefText: '',
            feature1Header: '',
            feature1Detail: '',
            previewFeature1Picture: null,
            selectedFeature1PictureFile: null,
            feature1PicturePath: '',
            feature2Header: '',
            feature2Detail: '',
            previewFeature2Picture: null,
            selectedFeature2PictureFile: null,
            feature2PicturePath: '',
            feature3Header: '',
            feature3Detail: '',
            previewFeature3Picture: null,
            selectedFeature3PictureFile: null,
            feature3PicturePath: '',
            feature4Header: '',
            feature4Detail: '',
            previewFeature4Picture: null,
            selectedFeature4PictureFile: null,
            feature4PicturePath: '',
            benefitsBriefText: '',
            benefit1Title: '',
            benefit1Detail: '',
            benefit2Title: '',
            benefit2Detail: '',
            benefit3Title: '',
            benefit3Detail: '',
            benefit4Title: '',
            benefit4Detail: '',
            benefit5Title: '',
            benefit5Detail: '',
            benefit6Title: '',
            benefit6Detail: '',
            benefit7Title: '',
            benefit7Detail: '',
            benefit8Title: '',
            benefit8Detail: '',
            benefit9Title: '',
            benefit9Detail: '',
            benefit10Title: '',
            benefit10Detail: '',
            benefit11Title: '',
            benefit11Detail: '',
            benefit12Title: '',
            benefit12Detail: '',
            benefit13Title: '',
            benefit13Detail: '',
            benefit14Title: '',
            benefit14Detail: '',
            benefit15Title: '',
            benefit15Detail: '',
            benefit16Title: '',
            benefit16Detail: '',
            registrationText: '',
            contactUsBriefText: '',
            contactUsText: '',
            contactUsTelephone: '',
            contactUsEmail: '',
            faqBriefText: '',
            footerTextLanding: '',
            footerFacebook: '',
            footerTwitter: '',
            footerLinkedin: '',
            footerInstagram: '',
            forgetPasswordEmailSubject: '',
            forgetPasswordEmailHeader: '',
            forgetPasswordEmailBody: '',
            welcomeEmailSubject: '',
            welcomeEmailHeader: '',
            welcomeEmailBody: '',
            invitationEmailSubject: '',
            invitationEmailHeader: '',
            invitationEmailBody: '',
            reportEmailSubject: '',
            reportEmailHeader: '',
            reportEmailBody: '',
            settings: {},
            appBarPicker: '',
            footerPicker: '',
            bodyPicker: '',
            headerPicker: ''
        }
    },
    methods: {
        onLogoSelected(e) {
            if (e != null) {
                this.selectedLogoFile = e
                const reader = new FileReader()
                reader.readAsDataURL(this.selectedLogoFile)
                reader.onload = e => {
                    this.previewLogo = e.target.result
                }

                let fd = new FormData()
                fd.append('image', this.selectedLogoFile)
                this.$store.dispatch('settings/uploadLogo', fd)
                    .then(response => {
                        //console.log(response)
                        if (response.status == 200) {
                            this.logoPath = '/' + response.data.dbPath
                        }
                    })
            } else {
                this.selectedLogoFile = null
                this.previewLogo = null
                this.logoPath = ''
            }
        },
        onFaviconSelected(e) {
            if (e != null) {
                this.selectedFaviconFile = e
                const reader = new FileReader()
                reader.readAsDataURL(this.selectedFaviconFile)
                reader.onload = e => {
                    this.previewFavicon = e.target.result
                }

                let fd = new FormData()
                fd.append('image', this.selectedFaviconFile)
                this.$store.dispatch('settings/uploadFavicon', fd)
                    .then(response => {
                        //console.log(response)
                        if (response.status == 200) {
                            this.faviconPath = '/' + response.data.dbPath
                        }
                    })
            } else {
                this.selectedFaviconFile = null
                this.previewFavicon = null
                this.faviconPath = ''
            }
        },
        onHomePictureSelected(e) {
            if (e != null) {
                this.selectedHomePictureFile = e
                const reader = new FileReader()
                reader.readAsDataURL(this.selectedHomePictureFile)
                reader.onload = e => {
                    this.previewHomePicture = e.target.result
                }

                let fd = new FormData()
                fd.append('image', this.selectedHomePictureFile)
                this.$store.dispatch('user/uploadImage', fd)
                    .then(response => {
                        //console.log(response)
                        if (response.status == 200) {
                            this.homePicturePath = '/' + response.data.dbPath
                        }
                    })
            } else {
                this.selectedHomePictureFile = null
                this.previewHomePicture = null
                this.homePicturePath = ''
            }
        },
        onFeature1PictureSelected(e) {
            if (e != null) {
                this.selectedFeature1PictureFile = e
                const reader = new FileReader()
                reader.readAsDataURL(this.selectedFeature1PictureFile)
                reader.onload = e => {
                    this.previewFeature1Picture = e.target.result
                }

                let fd = new FormData()
                fd.append('image', this.selectedFeature1PictureFile)
                this.$store.dispatch('user/uploadImage', fd)
                    .then(response => {
                        //console.log(response)
                        if (response.status == 200) {
                            this.feature1PicturePath = '/' + response.data.dbPath
                        }
                    })
            } else {
                this.selectedFeature1PictureFile = null
                this.previewFeature1Picture = null
                this.feature1PicturePath = ''
            }
        },
        onFeature2PictureSelected(e) {
            if (e != null) {
                this.selectedFeature2PictureFile = e
                const reader = new FileReader()
                reader.readAsDataURL(this.selectedFeature2PictureFile)
                reader.onload = e => {
                    this.previewFeature2Picture = e.target.result
                }

                let fd = new FormData()
                fd.append('image', this.selectedFeature2PictureFile)
                this.$store.dispatch('user/uploadImage', fd)
                    .then(response => {
                        //console.log(response)
                        if (response.status == 200) {
                            this.feature2PicturePath = '/' + response.data.dbPath
                        }
                    })
            } else {
                this.selectedFeature2PictureFile = null
                this.previewFeature2Picture = null
                this.feature2PicturePath = ''
            }
        },
        onFeature3PictureSelected(e) {
            if (e != null) {
                this.selectedFeature3PictureFile = e
                const reader = new FileReader()
                reader.readAsDataURL(this.selectedFeature3PictureFile)
                reader.onload = e => {
                    this.previewFeature3Picture = e.target.result
                }

                let fd = new FormData()
                fd.append('image', this.selectedFeature3PictureFile)
                this.$store.dispatch('user/uploadImage', fd)
                    .then(response => {
                        //console.log(response)
                        if (response.status == 200) {
                            this.feature3PicturePath = '/' + response.data.dbPath
                        }
                    })
            } else {
                this.selectedFeature3PictureFile = null
                this.previewFeature3Picture = null
                this.feature3PicturePath = ''
            }
        },
        onFeature4PictureSelected(e) {
            if (e != null) {
                this.selectedFeature4PictureFile = e
                const reader = new FileReader()
                reader.readAsDataURL(this.selectedFeature4PictureFile)
                reader.onload = e => {
                    this.previewFeature4Picture = e.target.result
                }

                let fd = new FormData()
                fd.append('image', this.selectedFeature4PictureFile)
                this.$store.dispatch('user/uploadImage', fd)
                    .then(response => {
                        //console.log(response)
                        if (response.status == 200) {
                            this.feature4PicturePath = '/' + response.data.dbPath
                        }
                    })
            } else {
                this.selectedFeature4PictureFile = null
                this.previewFeature4Picture = null
                this.feature4PicturePath = ''
            }
        },
        setTitle() {
            this.$store.dispatch('settings/changeSiteTitle', this.title)
        },
        setDescription() {
            this.$store.dispatch('settings/changeSiteDescription', this.description)
        },
        setFooterText() {
            this.$store.dispatch('settings/changeFooterText', this.footerText)
        },
        initialize() {
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('settings/fetchSiteSettings')
                .then(response => {
                    this.$store.dispatch('dashboard/cancelLoading')
                    this.settings = response.data
                    this.title = this.settings.siteTitle
                    this.description = this.settings.welComeMessage
                    this.footerText = this.settings.copyRightText
                    this.appVersion = this.settings.version
                    this.endExam = this.settings.endExam
                    this.tabChangeEndExam = this.settings.tabChangeEndExam
                    this.examPageLogo = this.settings.logoOnExamPage
                    this.audioVideoLength = this.settings.webCamVideoMaxLengthSec / 60
                    this.paidRegistration = this.settings.paidRegistration
                    this.registrationPrice = this.settings.registrationPrice
                    this.currency = this.settings.currency
                    this.currencySymbol = this.settings.currencySymbol
                    this.stripePubKey = this.settings.stripePubKey
                    this.stripeSecretKey = this.settings.stripeSecretKey
                    this.email = this.settings.defaultEmail
                    this.emailPassword = this.settings.password
                    this.emailPort = this.settings.port
                    this.emailHost = this.settings.host
                    this.allowEmail = this.settings.allowWelcomeEmail
                    this.allowFaq = this.settings.allowFaq
                    this.previewLogo = this.settings.logoPath != '' ? config.hostname + this.settings.logoPath : ''
                    this.logoPath = this.settings.logoPath != '' ? this.settings.logoPath : ''
                    this.previewFavicon = this.settings.faviconPath != '' ? config.hostname + this.settings.faviconPath : ''
                    this.faviconPath = this.settings.faviconPath != '' ? this.settings.faviconPath : ''
                    this.appBarPicker = this.settings.appBarColor
                    this.footerPicker = this.settings.footerColor
                    this.bodyPicker = this.settings.bodyColor
                    this.headerPicker = this.settings.headerColor
                    this.homeHeader1 = this.settings.homeHeader1
                    this.homeDetail1 = this.settings.homeDetail1
                    this.homeHeader2 = this.settings.homeHeader2
                    this.homeDetail2 = this.settings.homeDetail2
                    this.previewHomePicture = this.settings.homePicture != '' ? config.hostname + this.settings.homePicture : ''
                    this.homePicturePath = this.settings.homePicture != '' ? this.settings.homePicture : ''
                    this.homeBox1Header = this.settings.homeBox1Header
                    this.homeBox1Detail = this.settings.homeBox1Detail
                    this.homeBox2Header = this.settings.homeBox2Header
                    this.homeBox2Detail = this.settings.homeBox2Detail
                    this.homeBox3Header = this.settings.homeBox3Header
                    this.homeBox3Detail = this.settings.homeBox3Detail
                    this.homeBox4Header = this.settings.homeBox4Header
                    this.homeBox4Detail = this.settings.homeBox4Detail
                    this.pricingBriefText = this.settings.pricingDetail
                    this.featureBriefText = this.settings.featureMainDetail
                    this.feature1Header = this.settings.feature1Header
                    this.feature1Detail = this.settings.feature1Detail
                    this.previewFeature1Picture = this.settings.feature1Picture != '' ? config.hostname + this.settings.feature1Picture : ''
                    this.feature1PicturePath = this.settings.feature1Picture != '' ? this.settings.feature1Picture : ''
                    this.feature2Header = this.settings.feature2Header
                    this.feature2Detail = this.settings.feature2Detail
                    this.previewFeature2Picture = this.settings.feature2Picture != '' ? config.hostname + this.settings.feature2Picture : ''
                    this.feature2PicturePath = this.settings.feature2Picture != '' ? this.settings.feature2Picture : ''
                    this.feature3Header = this.settings.feature3Header
                    this.feature3Detail = this.settings.feature3Detail
                    this.previewFeature3Picture = this.settings.feature3Picture != '' ? config.hostname + this.settings.feature3Picture : ''
                    this.feature3PicturePath = this.settings.feature3Picture != '' ? this.settings.feature3Picture : ''
                    this.feature4Header = this.settings.feature4Header
                    this.feature4Detail = this.settings.feature4Detail
                    this.previewFeature4Picture = this.settings.feature4Picture != '' ? config.hostname + this.settings.feature4Picture : ''
                    this.feature4PicturePath = this.settings.feature4Picture != '' ? this.settings.feature4Picture : ''
                    this.benefitsBriefText = this.settings.benefitsDetail
                    this.benefit1Title = this.settings.benefit1
                    this.benefit1Detail = this.settings.benefit1Detail
                    this.benefit2Title = this.settings.benefit2
                    this.benefit2Detail = this.settings.benefit2Detail
                    this.benefit3Title = this.settings.benefit3
                    this.benefit3Detail = this.settings.benefit3Detail
                    this.benefit4Title = this.settings.benefit4
                    this.benefit4Detail = this.settings.benefit4Detail
                    this.benefit5Title = this.settings.benefit5
                    this.benefit5Detail = this.settings.benefit5Detail
                    this.benefit6Title = this.settings.benefit6
                    this.benefit6Detail = this.settings.benefit6Detail
                    this.benefit7Title = this.settings.benefit7
                    this.benefit7Detail = this.settings.benefit7Detail
                    this.benefit8Title = this.settings.benefit8
                    this.benefit8Detail = this.settings.benefit8Detail
                    this.benefit9Title = this.settings.benefit9
                    this.benefit9Detail = this.settings.benefit9Detail
                    this.benefit10Title = this.settings.benefit10
                    this.benefit10Detail = this.settings.benefit10Detail
                    this.benefit11Title = this.settings.benefit11
                    this.benefit11Detail = this.settings.benefit11Detail
                    this.benefit12Title = this.settings.benefit12
                    this.benefit12Detail = this.settings.benefit12Detail
                    this.benefit13Title = this.settings.benefit13
                    this.benefit13Detail = this.settings.benefit13Detail
                    this.benefit14Title = this.settings.benefit14
                    this.benefit14Detail = this.settings.benefit14Detail
                    this.benefit15Title = this.settings.benefit15
                    this.benefit15Detail = this.settings.benefit15Detail
                    this.benefit16Title = this.settings.benefit16
                    this.benefit16Detail = this.settings.benefit16Detail
                    this.registrationText = this.settings.registrationText
                    this.contactUsBriefText = this.settings.contactUsDetail
                    this.contactUsText = this.settings.contactUsText
                    this.contactUsTelephone = this.settings.contactUsTelephone
                    this.contactUsEmail = this.settings.contactUsEmail
                    this.faqBriefText = this.settings.faqDetail
                    this.footerTextLanding = this.settings.footerText
                    this.footerFacebook = this.settings.footerFacebook
                    this.footerTwitter = this.settings.footerTwitter
                    this.footerLinkedin = this.settings.footerLinkedin
                    this.footerInstagram = this.settings.footerInstagram
                    this.forgetPasswordEmailSubject = this.settings.forgetPasswordEmailSubject
                    this.forgetPasswordEmailHeader = this.settings.forgetPasswordEmailHeader
                    this.forgetPasswordEmailBody = this.settings.forgetPasswordEmailBody
                    this.welcomeEmailSubject = this.settings.welcomeEmailSubject
                    this.welcomeEmailHeader = this.settings.welcomeEmailHeader
                    this.welcomeEmailBody = this.settings.welcomeEmailBody
                    this.invitationEmailSubject = this.settings.invitationEmailSubject
                    this.invitationEmailHeader = this.settings.invitationEmailHeader
                    this.invitationEmailBody = this.settings.invitationEmailBody
                    this.reportEmailSubject = this.settings.reportEmailSubject
                    this.reportEmailHeader = this.settings.reportEmailHeader
                    this.reportEmailBody = this.settings.reportEmailBody
                })
                .catch(err => {
                    this.$router.push({ name: 'NoInternet' })
                    console.log(err)
                })
        },

        updateGeneralSettings() {
            this.$store.dispatch('dashboard/applyLoading')
            const objSettings = {
                siteSettingsId: this.settings.siteSettingsId,
                siteTitle: this.title,
                welComeMessage: this.description,
                copyRightText: this.footerText,
                version: this.appVersion,
                logoPath: this.logoPath,
                faviconPath: this.faviconPath,
                allowWelcomeEmail: this.allowEmail,
                allowFaq: this.allowFaq,
                lastUpdatedBy: parseInt(localStorage.getItem('loggedUserId')),
            }
            this.$store.dispatch('settings/updateGeneralSettings', objSettings)
                .then(response => {
                    this.$store.dispatch('dashboard/cancelLoading')
                    if (response.status == 200) {
                        this.$root.$emit('message_from_parent', this.$t('successfullyUpdated'))
                    } else if (response.status == 202) {
                        this.$root.$emit('message_from_parent_long', response.data.responseMsg)
                    }
                })
                .catch(err => {
                    this.$router.push({ name: 'NoInternet' })
                    console.log(err)
                })
        },
        updateExamSettings() {
            this.$store.dispatch('dashboard/applyLoading')
            const objSettings = {
                siteSettingsId: this.settings.siteSettingsId,
                endExam: this.endExam,
                tabChangeEndExam: this.tabChangeEndExam,
                logoOnExamPage: this.examPageLogo,
                webCamVideoMaxLengthSec: this.audioVideoLength * 60,
                lastUpdatedBy: parseInt(localStorage.getItem('loggedUserId')),
            }
            this.$store.dispatch('settings/updateExamSettings', objSettings)
                .then(response => {
                    this.$store.dispatch('dashboard/cancelLoading')
                    if (response.status == 200) {
                        this.$root.$emit('message_from_parent', this.$t('successfullyUpdated'))
                    } else if (response.status == 202) {
                        this.$root.$emit('message_from_parent_long', this.$t(response.data.responseMsg))
                    }
                })
                .catch(err => {
                    this.$router.push({ name: 'NoInternet' })
                    console.log(err)
                })
        },
        updatePaymentSettings() {
            this.$store.dispatch('dashboard/applyLoading')
            const objSettings = {
                siteSettingsId: this.settings.siteSettingsId,
                paidRegistration: this.paidRegistration,
                registrationPrice: this.registrationPrice == '' ? 0 : this.registrationPrice,
                currency: this.currency,
                currencySymbol: this.currencySymbol,
                stripePubKey: this.stripePubKey,
                stripeSecretKey: this.stripeSecretKey,
                lastUpdatedBy: parseInt(localStorage.getItem('loggedUserId')),
            }
            this.$store.dispatch('settings/updatePaymentSettings', objSettings)
                .then(response => {
                    this.$store.dispatch('dashboard/cancelLoading')
                    if (response.status == 200) {
                        this.$root.$emit('message_from_parent', this.$t('successfullyUpdated'))
                    } else if (response.status == 202) {
                        this.$root.$emit('message_from_parent_long', this.$t(response.data.responseMsg))
                    }
                })
                .catch(err => {
                    this.$router.push({ name: 'NoInternet' })
                    console.log(err)
                })
        },
        updateEmailSettings() {
            this.$store.dispatch('dashboard/applyLoading')
            const objSettings = {
                siteSettingsId: this.settings.siteSettingsId,
                defaultEmail: this.email,
                password: this.emailPassword,
                host: this.emailHost,
                port: this.emailPort == '' ? 0 : this.emailPort,
                lastUpdatedBy: parseInt(localStorage.getItem('loggedUserId')),
            }
            this.$store.dispatch('settings/updateEmailSettings', objSettings)
                .then(response => {
                    this.$store.dispatch('dashboard/cancelLoading')
                    if (response.status == 200) {
                        this.$root.$emit('message_from_parent', this.$t('successfullyUpdated'))
                    } else if (response.status == 202) {
                        this.$root.$emit('message_from_parent_long', this.$t(response.data.responseMsg))
                    }
                })
                .catch(err => {
                    this.$router.push({ name: 'NoInternet' })
                    console.log(err)
                })
        },
        updateColorSettings() {
            this.$store.dispatch('dashboard/applyLoading')
            const objSettings = {
                siteSettingsId: this.settings.siteSettingsId,
                appBarColor: this.appBarPicker,
                footerColor: this.footerPicker,
                bodyColor: this.bodyPicker,
                headerColor: this.headerPicker,
                lastUpdatedBy: parseInt(localStorage.getItem('loggedUserId')),
            }
            this.$store.dispatch('settings/updateColorSettings', objSettings)
                .then(response => {
                    this.$store.dispatch('dashboard/cancelLoading')
                    if (response.status == 200) {
                        this.$root.$emit('message_from_parent', this.$t('successfullyUpdated'))
                    } else if (response.status == 202) {
                        this.$root.$emit('message_from_parent_long', this.$t(response.data.responseMsg))
                    }
                })
                .catch(err => {
                    this.$router.push({ name: 'NoInternet' })
                    console.log(err)
                })
        },
        updateLandingSettings() {
            this.$store.dispatch('dashboard/applyLoading')
            const objSettings = {
                siteSettingsId: this.settings.siteSettingsId,
                homeHeader1: this.homeHeader1,
                homeDetail1: this.homeDetail1,
                homeHeader2: this.homeHeader2,
                homeDetail2: this.homeDetail2,
                homePicture: this.homePicturePath,
                homeBox1Header: this.homeBox1Header,
                homeBox1Detail: this.homeBox1Detail,
                homeBox2Header: this.homeBox2Header,
                homeBox2Detail: this.homeBox2Detail,
                homeBox3Header: this.homeBox3Header,
                homeBox3Detail: this.homeBox3Detail,
                homeBox4Header: this.homeBox4Header,
                homeBox4Detail: this.homeBox4Detail,
                feature1Header: this.feature1Header,
                feature1Detail: this.feature1Detail,
                feature1Picture: this.feature1PicturePath,
                feature2Header: this.feature2Header,
                feature2Detail: this.feature2Detail,
                feature2Picture: this.feature2PicturePath,
                feature3Header: this.feature3Header,
                feature3Detail: this.feature3Detail,
                feature3Picture: this.feature3PicturePath,
                feature4Header: this.feature4Header,
                feature4Detail: this.feature4Detail,
                feature4Picture: this.feature4PicturePath,
                pricingDetail: this.pricingBriefText,
                featureMainDetail: this.featureBriefText,
                contactUsDetail: this.contactUsBriefText,
                faqDetail: this.faqBriefText,
                benefitsDetail: this.benefitsBriefText,
                benefit1: this.benefit1Title,
                benefit1Detail: this.benefit1Detail,
                benefit2: this.benefit2Title,
                benefit2Detail: this.benefit2Detail,
                benefit3: this.benefit3Title,
                benefit3Detail: this.benefit3Detail,
                benefit4: this.benefit4Title,
                benefit4Detail: this.benefit4Detail,
                benefit5: this.benefit5Title,
                benefit5Detail: this.benefit5Detail,
                benefit6: this.benefit6Title,
                benefit6Detail: this.benefit6Detail,
                benefit7: this.benefit7Title,
                benefit7Detail: this.benefit7Detail,
                benefit8: this.benefit8Title,
                benefit8Detail: this.benefit8Detail,
                benefit9: this.benefit9Title,
                benefit9Detail: this.benefit9Detail,
                benefit10: this.benefit10Title,
                benefit10Detail: this.benefit10Detail,
                benefit11: this.benefit11Title,
                benefit11Detail: this.benefit11Detail,
                benefit12: this.benefit12Title,
                benefit12Detail: this.benefit12Detail,
                benefit13: this.benefit13Title,
                benefit13Detail: this.benefit13Detail,
                benefit14: this.benefit14Title,
                benefit14Detail: this.benefit14Detail,
                benefit15: this.benefit15Title,
                benefit15Detail: this.benefit15Detail,
                benefit16: this.benefit16Title,
                benefit16Detail: this.benefit16Detail,
                registrationText: this.registrationText,
                contactUsText: this.contactUsText,
                contactUsTelephone: this.contactUsTelephone,
                contactUsEmail: this.contactUsEmail,
                footerText: this.footerTextLanding,
                footerFacebook: this.footerFacebook,
                footerTwitter: this.footerTwitter,
                footerLinkedin: this.footerLinkedin,
                footerInstagram: this.footerInstagram,
                lastUpdatedBy: parseInt(localStorage.getItem('loggedUserId')),
            }
            this.$store.dispatch('settings/updateLandingSettings', objSettings)
                .then(response => {
                    this.$store.dispatch('dashboard/cancelLoading')
                    if (response.status == 200) {
                        this.$root.$emit('message_from_parent', this.$t('successfullyUpdated'))
                    } else if (response.status == 202) {
                        this.$root.$emit('message_from_parent_long', this.$t(response.data.responseMsg))
                    }
                })
                .catch(err => {
                    this.$router.push({ name: 'NoInternet' })
                    console.log(err)
                })
        },
        updateEmailTextSettings() {
            this.$store.dispatch('dashboard/applyLoading')
            const objSettings = {
                siteSettingsId: this.settings.siteSettingsId,
                forgetPasswordEmailSubject: this.forgetPasswordEmailSubject,
                forgetPasswordEmailHeader: this.forgetPasswordEmailHeader,
                forgetPasswordEmailBody: this.forgetPasswordEmailBody,
                welcomeEmailSubject: this.welcomeEmailSubject,
                welcomeEmailHeader: this.welcomeEmailHeader,
                welcomeEmailBody: this.welcomeEmailBody,
                invitationEmailSubject: this.invitationEmailSubject,
                invitationEmailHeader: this.invitationEmailHeader,
                invitationEmailBody: this.invitationEmailBody,
                reportEmailSubject: this.reportEmailSubject,
                reportEmailHeader: this.reportEmailHeader,
                reportEmailBody: this.reportEmailBody,
                lastUpdatedBy: parseInt(localStorage.getItem('loggedUserId')),
            }
            this.$store.dispatch('settings/updateEmailTextSettings', objSettings)
                .then(response => {
                    this.$store.dispatch('dashboard/cancelLoading')
                    if (response.status == 200) {
                        this.$root.$emit('message_from_parent', this.$t('successfullyUpdated'))
                    } else if (response.status == 202) {
                        this.$root.$emit('message_from_parent_long', this.$t(response.data.responseMsg))
                    }
                })
                .catch(err => {
                    this.$router.push({ name: 'NoInternet' })
                    console.log(err)
                })
        },
        saveGeneral() {
            if (this.$refs.formGeneral.validate()) {
                this.updateGeneralSettings()
            }
        },
        saveExam() {
            if (this.$refs.formExam.validate()) {
                this.updateExamSettings()
            }
        },
        savePayment() {
            if (this.$refs.formPayment.validate()) {
                this.updatePaymentSettings()
            }
        },
        saveEmail() {
            if (this.$refs.formEmail.validate()) {
                this.updateEmailSettings()
            }
        },
        saveColor() {
            if (this.$refs.formColor.validate()) {
                this.updateColorSettings()
            }
        },
        saveLanding() {
            if (this.$refs.formLanding.validate()) {
                this.updateLandingSettings()
            }
        },
        saveEmailText() {
            if (this.$refs.formEmailText.validate()) {
                this.updateEmailTextSettings()
            }
        },
    },
    computed: {
        colorAppBar() {
            return this.$store.dispatch('settings/changeAppBarColor', this.appBarPicker)
        },
        colorFooter() {
            return this.$store.dispatch('settings/changeFooterColor', this.footerPicker)
        },
        colorBody() {
            return this.$store.dispatch('settings/changeBgColor', this.bodyPicker)
        },
        colorHeader() {
            return this.$store.dispatch('settings/changeHeaderColor', this.headerPicker)
        }
    },
    created() {
        this.initialize()
    }
}
</script>