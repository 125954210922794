<template>
  <div>
    <div class="iframe-container">
      <audio ref="videoPlayer" class="video-js responsive-iframe vjs-default-skin"></audio>
    </div>
    <v-btn outlined class="text-capitalize ma-1" @click="startRecording()" v-bind:disabled="isStartRecording">{{
      $t('startRecording') }}</v-btn>
    <v-btn outlined class="text-capitalize ma-1" @click="stopVideo()" v-bind:disabled="isStopRecording">{{ $t('stop')
    }}</v-btn>
    <v-btn outlined class="text-capitalize ma-1" @click="submitVideo()" v-bind:disabled="isSaveDisabled">{{ submitText
    }}</v-btn>
    <v-btn outlined class="text-capitalize ma-1" @click="retakeVideo()" v-bind:disabled="isRetakeDisabled">{{
      $t('retake') }}</v-btn>
  </div>
</template>

<script>
import WaveSurfer from 'wavesurfer.js'
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js'
WaveSurfer.microphone = MicrophonePlugin
import videojs from 'video.js'
import RecordRTC from 'recordrtc'
import 'video.js/dist/video-js.css'
import 'videojs-record/dist/css/videojs.record.css'
import 'vue-video-player/src/custom-theme.css'
import 'videojs-record/dist/videojs.record.js'

import 'videojs-wavesurfer/dist/css/videojs.wavesurfer.css'
import 'videojs-wavesurfer/dist/videojs.wavesurfer.js'

export default {
  name: 'VideoRecorder',
  data() {
    return {
      player: null,
      retake: 0,
      isSaveDisabled: true,
      isStartRecording: false,
      isRetakeDisabled: true,
      isStopRecording: true,
      submitText: this.$t('save'),
      options: {
        controls: true,
        fluid: false,
        autoplay: true,
        bigPlayButton: false,
        convertAuto: true,
        controlBar: {
          volumePanel: false,
          deviceButton: false,
          recordToggle: false
        },
        plugins: {
          wavesurfer: {
            backend: 'WebAudio',
            waveColor: '#36393b',
            progressColor: 'black',
            debug: true,
            cursorWidth: 1,
            displayMilliseconds: true,
            hideScrollbar: true,
            plugins: [
              // enable microphone plugin
              WaveSurfer.microphone.create({
                bufferSize: 4096,
                numberOfInputChannels: 1,
                numberOfOutputChannels: 1,
                constraints: {
                  video: false,
                  audio: true
                }
              })
            ]
          },
          record: {
            image: false,
            audio: true,
            video: false,
            maxLength: this.$store.getters['settings/webCamVideoLength'],
            debug: true,
          },
        },
      },
    }
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer,
      this.options, () => {
        var msg = 'Using video.js ' + videojs.VERSION +
          ' with videojs-record ' + videojs.getPluginVersion('record') +
          ' and recordrtc ' + RecordRTC.version +
          ' and wavesurfer.js ' + WaveSurfer.VERSION
        videojs.log(msg);
      })

    this.player.on('deviceReady', () => {
      this.player.record().start();
      console.log('device ready:');
    });

    this.player.on('deviceError', () => {
      console.log('device error:', this.player.deviceErrorCode);
    });

    this.player.on('error', (element, error) => {
      console.error(error);
    });

    this.player.on('startRecord', () => {
      console.log('started recording!');
    });

    this.player.on('finishRecord', () => {
      this.isSaveDisabled = false;
      this.isStopRecording = true;
      this.isRetakeDisabled = false;
      // the blob object contains the recorded data that
      // can be downloaded by the user, stored on server etc.
      console.log('finished recording: ', this.player.recordedData);
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.$store.dispatch('question/storeVideoDuration', 0)
      this.player.dispose()
    }
  },
  methods: {
    startRecording() {
      this.isStartRecording = true;
      this.isStopRecording = false;
      this.player.record().getDevice();
    },
    submitVideo() {
      this.isSaveDisabled = true;
      this.isRetakeDisabled = true;
      var data = this.player.recordedData;
      //console.log('Duration', this.player.record().getDuration())
      var formData = new FormData();
      var formattedName = data.name.split('.')[0].concat('.oga')
      formData.append('audio', data, formattedName);
      this.submitText = this.$t('uploading') + formattedName;
      this.$store.dispatch('dashboard/applyLoading')

      this.$store.dispatch('question/uploadVideo', formData)
        .then(response => {
          this.$store.dispatch('dashboard/cancelLoading')
          if (response.status == 200) {
            this.$store.dispatch('question/storeVideoDuration', this.player.record().getDuration())
            this.$store.dispatch('question/storeWebCamPath', '/' + response.data.dbPath)
            this.submitText = this.$t('uploadComplete');
          }
        })
        .catch(err => {
          this.submitText = this.$t('uploadFailed');
          this.$store.dispatch('dashboard/cancelLoading')
          console.log(err)
        })
    },
    stopVideo() {
      this.isStopRecording = true;
      this.isRetakeDisabled = false;
      this.player.record().stop();
    },
    retakeVideo() {
      this.isSaveDisabled = true;
      this.isRetakeDisabled = true;
      this.isStopRecording = false;
      this.retake += 1;
      this.player.record().start();
    }
  },
}
</script>

<style scoped>
.video-js {
  width: 100%;
  height: 100%;
}

.iframe-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}
</style>